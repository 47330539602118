import React, { useContext, useEffect } from "react";
import { Context } from "redux/Store";
import { getUrlFromSlug } from "libs/services/utils";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Seo from "common/seo";
import ProductDetails from "common/product-details";
import AccordionContent from "common/accordion";
import { TypeA, TypeC } from "common/callouts";

import frontImage from "images/sun/sunproducts/agedefense-product-front.webp";
import frontCapImage from "images/sun/sunproducts/agedefense-product-front-cap.webp";
import backImage from "images/sun/sunproducts/agedefense-product-back.webp";


import frontZoomImage from "images/sun/sunproducts/agedefense-product-front-zoom.webp";
import frontCapZoomImage from "images/sun/sunproducts/agedefense-product-front-cap-zoom.webp";
import backZoomImage from "images/sun/sunproducts/agedefense-product-back-zoom.webp";

import img2 from "images/sun/sunproducts/agedefense-periorbitallines-chart.webp";
import img3 from "images/sun/sunproducts/agedefense-crowsfeet-chart.webp";
import img4 from "images/sun/sunproducts/agedefense-neckwrinkles-chart.webp";
import img5 from "images/sun/sunproducts/agedefense-photographic-results.webp";
import img6 from "images/sun/sunproducts/agedefense-feedback-chart.webp";

import img8 from "images/callouts/cout-patient-resource.webp";
import img9 from "images/callouts/cout-footer-dry-skin-therapy.webp";
import img10 from "images/callouts/cout-footer-5aox.webp";
import img11 from "images/callouts/cout-footer-journal.webp";

import "./agedefense.scss";

/* function BoldText({children}) {
  return <span style={{fontWeight: 'bold'}}>{children}</span>;
} */

function EucerinSunAgeDefensePage({ pageContext }) {
  const { state, dispatch } = useContext(Context);

  useEffect(() => {
    setTimeout(() => {
      const myHash = window.location.hash.slice(1);
      const myScrollSpot = document.getElementById(myHash);
      if (myScrollSpot) {
        const currentBlock = myScrollSpot.getBoundingClientRect();
        window.scrollTo(0, currentBlock.top - 150);
      }
    }, 500);
  }, []);

  return (
    <Layout
      pageClass="page-sun-eucerinsunproducts-age-defense page-level-3"
      breadcrumbData={pageContext.breadcrumb}
    >
      <Seo
        pageTitle="Eucerin Sun Age Defense"
        pageDescription="Learn about Eucerin Sun Age Defense for aging skin"
      />
      <div className="inner-body-content">
        <div className="top-content-level-3">
          <section className="inner-centered-container">
            <ProductDetails
              categoryBgColor="#E07810"
              categoryName="SUN CARE"
              categoryFontColor="#ffffff"
              productName="Eucerin<sup>®</sup> Sun Age Defense"
              productSlider={[frontCapImage, frontImage, backImage]}
              zoomImages={[frontCapZoomImage, frontZoomImage, backZoomImage]}
            >
              <div className="functions">
                <p className="font-semi-bold mango-tango-text sub-title">
                  Helps reduce signs of skin aging*
                </p>
                <p>
                  <span className="font-semi-bold">
                    Eucerin Sun Age Defense
                  </span>{" "}
                  is formulated with hyaluronic acid to help visibly reduce the
                  signs of skin aging.* With a silky, lightweight feel, it works
                  well alone or under makeup.
                </p>
                <ul className="mango-tango-bullets">
                  <li>
                    <span>Blends seamlessly with all skin tones</span>
                  </li>
                  <li>
                    <span>
                      <span className="font-semi-bold">
                        Formulation helps defend against free radicals
                      </span>
                    </span>
                  </li>
                  <li>
                    <span>
                      Reduction in fine lines as early as Week 1<sup>1</sup>
                    </span>
                  </li>
                </ul>
              </div>
              <div className="key-ingredients">
                <p className="font-semi-bold mango-tango-text sub-title">
                  Active ingredients
                </p>
                <p className="no-mb font-semi-bold">
                  Avobenzone 3%, homosalate 10%, octisalate 5%, octocrylene 10%
                </p>
                <ul className="mango-tango-bullets">
                  <li>
                    <span>UVA/UVB broad-spectrum protection</span>
                  </li>
                </ul>
              </div>
              <div className="key-ingredients no-brd">
                <p className="font-semi-bold mango-tango-text sub-title">
                  Key ingredients
                </p>
                <p className="no-mb font-semi-bold">
                  5 AOX Shield<sup>&trade;</sup> helps to:
                </p>
                <ul className="mango-tango-bullets">
                  <li>
                    <span>Neutralize free radicals</span>
                  </li>
                  <li>
                    <span>Guard against free radical formation</span>
                  </li>
                  <li>
                    <span>Defend against natural antioxidant breakdown</span>
                  </li>
                </ul>
                <p className="no-mb font-semi-bold">Hyaluronic acid</p>
                <ul className="mango-tango-bullets">
                  <li>
                    <span>
                      With continuous use, helps to visibly reduce the look of
                      fine lines and wrinkles*
                    </span>
                  </li>
                </ul>
              </div>
            </ProductDetails>
            <Row>
              <Col xs={12}>
                <div className="features">
                  <p className="font-semi-bold mango-tango-text sub-title">
                    Formulated for aging skin
                  </p>
                  <ul className="checkmarks mango-tango">
                    <li>
                      <span>Hypoallergenic</span>
                    </li>
                    <li>
                      <span>Non-comedogenic</span>
                    </li>
                    <li>
                      <span>Ophthalmologist-tested</span>
                    </li>
                  </ul>
                  <ul className="checkmarks mango-tango">
                    <li>
                      <span>Fragrance-free</span>
                    </li>
                    <li>
                      <span>Octinoxate-free</span>
                    </li>
                    <li>
                      <span>Oxybenzone-free</span>
                    </li>
                  </ul>
                </div>
                <p className="references-symbols last">
                  *With continuous use after 1 week. Use as directed with other
                  sun protection measures (see Drug Facts Box).
                </p>
                <p className="references last">
                  <span className="font-bold">Reference: 1.</span> Data on file.
                  Beiersdorf Inc.
                </p>
              </Col>
            </Row>
          </section>
        </div>
        <div className="gradient-bg">
          <section className="inner-centered-container">
            <AccordionContent
              accordionHeading="PERIORBITAL FINE LINES"
              productThemeColor="sun-mango-tango"
              id="periorbitalfinelines"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img2}
                    className="accordion-chart"
                    alt="Graph with decrease in periobital fine lines seen as early as week 1"
                  />
                  <p className="footnotes">
                    <span className="font-bold">Study design:</span> Product was
                    applied 2x daily. Clinical grading of wrinkles of 44 female
                    subjects (FST I-VI, ages 43-62, with mild to moderate fine
                    lines and wrinkles—scores 3-6 on a 10-point modified
                    Griffith’s scale) with assessments at baseline, Week 1, Week
                    2, and Week 4.
                  </p>
                  <p className="references-symbols double last">
                    **Statistically significant difference vs baseline;{" "}
                    <span className="font-italic">p&lt;</span>0.001.
                  </p>

                  <p className="references last">
                    <span className="font-bold">Reference: 1.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    At Week 4, <br className="show-desktop" />
                    statistically <br className="show-desktop" />
                    significant decreases <br className="show-desktop" />
                    were demonstrated <br className="show-desktop" />
                    in periorbital fine <br className="show-desktop" /> lines
                  </div>
                </Col>
              </Row>
            </AccordionContent>
            <AccordionContent
              accordionHeading="CROW'S FEET WRINKLES"
              productThemeColor="sun-mango-tango"
              id="crowsfeetwrinkles"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img3}
                    className="accordion-chart"
                    alt="Graph with decrease in crow's feet wrinkles seen as early as week 1"
                  />
                  <p className="footnotes">
                    <span className="font-bold">Study design:</span> Product was
                    applied 2x daily. Clinical grading of wrinkles of 44 female
                    subjects (FST I-VI, ages 43-62, with mild to moderate fine
                    lines and wrinkles—scores 3-6 on a 10-point modified
                    Griffith’s scale) with assessments at baseline, Week 1, Week
                    2, and Week 4.
                  </p>
                  <p className="references-symbols double last">
                    **Statistically significant difference vs baseline;{" "}
                    <span className="font-italic">p&lt;</span>0.001.
                  </p>

                  <p className="references">
                    <span className="font-bold">Reference: 1.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    At Week 4, <br className="show-desktop" />
                    statistically <br className="show-desktop" />
                    significant decreases <br className="show-desktop" />
                    were demonstrated <br className="show-desktop" />
                    in crow's feet
                  </div>
                </Col>
              </Row>
            </AccordionContent>
            <AccordionContent
              accordionHeading="NECK WRINKLES"
              productThemeColor="sun-mango-tango"
              id="neckwrinkles"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img4}
                    className="accordion-chart"
                    alt="Graph with decrease in neck wrinkles seen as early as week 1"
                  />
                  <p className="footnotes">
                    <span className="font-bold">Study design:</span> Product was
                    applied 2x daily. Clinical grading of wrinkles of 44 female
                    subjects (FST I-VI, ages 43-62, with mild to moderate fine
                    lines and wrinkles—scores 3-6 on a 10-point modified
                    Griffith’s scale) with assessments at baseline, Week 1, Week
                    2, and Week 4.
                  </p>
                  <p className="references-symbols double last">
                    **Statistically significant difference vs baseline;{" "}
                    <span className="font-italic">p&lt;</span>0.001.
                  </p>

                  <p className="references">
                    <span className="font-bold">Reference: 1.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    At Week 4, <br className="show-desktop" />
                    statistically <br className="show-desktop" />
                    significant decreases <br className="show-desktop" />
                    were demonstrated <br className="show-desktop" />
                    in neck wrinkles
                  </div>
                </Col>
              </Row>
            </AccordionContent>
            <AccordionContent
              accordionHeading="Photographic Results"
              productThemeColor="sun-mango-tango"
              id="photoagingresults"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img5}
                    className="accordion-chart"
                    alt="Twice-daily use of Eucerin Age Defense visibly reduced the appearance of fine lines and wrinkles at Week 4"
                  />
                  <p className="references">
                    <span className="font-bold">Reference: 1.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Clinically shown to <br className="show-desktop" /> visibly
                    reduce the <br className="show-desktop" />
                    appearance of fine <br className="show-desktop" />
                    lines and wrinkles
                  </div>
                </Col>
              </Row>
            </AccordionContent>
            <AccordionContent
              accordionHeading="Survey Results"
              productThemeColor="sun-mango-tango"
              id="surveyresults"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img6}
                    className="accordion-chart"
                    alt="Real-world feedback from patients after using Eucerin Sun Age Defense"
                  />
                  <p className="references-symbols last">
                    *With continuous use after 1 week. Use as directed with
                    other sun protection measures (see Drug Facts Box).
                  </p>
                  <p className="references">
                    <span className="font-bold">Reference: 1.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Sun protection with <br className="show-desktop" />
                    added age defense <br className="show-desktop" />
                    for faces of any skin
                    <br className="show-desktop" /> tone*
                  </div>
                </Col>
              </Row>
            </AccordionContent>
          </section>
        </div>
        <section className="callouts-container gradient-bg section-support-resource orange">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={8} order={{ xs: 2, md: 1 }} className="">
                <TypeC
                  buttonText="DOWNLOAD HERE"
                  buttonUrl={getUrlFromSlug(
                    "eucerin-sun-patient-brochure",
                    state?.sanityData?.allResources
                  )}
                  className="center-v"
                  isExternalLink={true}
                  targetOpen="_blank"
                >
                  <p className="reg-text-big">
                    Support your recommendation with this resource
                  </p>
                  <p>
                    Provide your patients with sun protection tips and more.
                  </p>
                </TypeC>
              </Col>
              <Col sm={12} md={4} order={{ xs: 1, md: 2 }} className="">
                <img
                  src={img8}
                  alt="Everyone under the sun needs protection"
                  className="cout-product-img"
                />
              </Col>
            </Row>
          </div>
        </section>
        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img9}
                  imgAlt="Hydration"
                  caption="Recommend daily hydration <br class='show-desktop' /> along with sun protection"
                  buttonText="Explore Body Care"
                  buttonUrl="/body"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img10}
                  imgAlt="5AOX Shield"
                  caption="Formulas to help defend against damage from oxidative stress"
                  buttonText="See the modes of defense"
                  buttonUrl="/sun/freeradicaldefense"
                  imgStyles={{ height: 165 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img11}
                  imgAlt="JAAD"
                  caption="Discover how perspectives in sun protection are evolving"
                  buttonText="VIEW JOURNAL ARTICLES"
                  buttonUrl="https://www.jaad.org/issue/S0190-9622(22)X0002-9"
                  imgStyles={{ height: 165 }}
                  isExternalLink={true}
                  targetOpen="_blank"
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default EucerinSunAgeDefensePage;
